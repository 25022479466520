import { useEffect, useMemo, useState } from "react";
import { useTranslation } from "react-i18next";

import ProjectAvailableUnits from "~/components/organisms/ProjectAvailableUnits";
import Section from "~/components/templates/SectionProject";
import { IProject } from "~/entities/project";

export interface SectionProjectAvailableUnitsProps {
  className?: string;
  id: string;
  title: string;
  description: string;
  project: IProject;
}

const SectionProjectAvailableUnits: React.FC<
  SectionProjectAvailableUnitsProps
> = ({ className = "", id, title, description, project }) => {
  const { t } = useTranslation();
  const [percentSold, setPercentSold] = useState<string>("0");

  useEffect(() => {
    function calculatePercentage(project: IProject): string {
      const total = project?.manualTotalUnitsCount || project?.totalUnits;
      const available =
        project?.manualAvailableUnitsCount || project?.availableUnits;

      const percent = ((total - available) / total) * 100;
      const formattedPercent =
        percent % 1 === 0 ? percent.toFixed(0) : percent.toFixed(2);
      return `${formattedPercent}%`;
    }

    if (project) {
      const percent = calculatePercentage(project);
      setPercentSold(percent);
    }
  }, [project]);

  const projectAvaiableUnits = project.unitTypes.filter(
    (item) => !!item.availableUnits
  );

  const soldNumber = useMemo(() => {
    const totalUnits = project?.manualTotalUnitsCount || project.totalUnits;
    const availableUnits =
      project?.manualAvailableUnitsCount || project.availableUnits;

    return totalUnits - availableUnits;
  }, [
    project.availableUnits,
    project?.manualAvailableUnitsCount,
    project?.manualTotalUnitsCount,
    project.totalUnits,
  ]);

  if (!project) return null;

  return (
    <Section id={id} className={className}>
      <ProjectAvailableUnits
        availableUnits={projectAvaiableUnits}
        title={t(title)}
        description={t(description, {
          soldNumber: soldNumber,
          percent: percentSold,
        })}
      />
    </Section>
  );
};

export default SectionProjectAvailableUnits;
