import { useTranslation } from "react-i18next";

import { HorizontalTable } from "~/components/atoms/Table";
import { Column } from "~/components/atoms/Table/types";
import Typo from "~/components/atoms/Typo";
import { TUnitType } from "~/entities/unitType";
import { cn } from "~/utilities/cn";
import { ETypoColor } from "~/utilities/enums/Colors";
import { ETypoTag, ETypoVariant } from "~/utilities/enums/Typo";
import formatCurrencyRange from "~/utilities/helpers/formatCurrencyRange";
import { formatRangeValue } from "~/utilities/helpers/formatRangeValue";

export interface ProjectAvailableUnitsProps {
  className?: string;
  availableUnits: TUnitType[];
  title: string;
  description?: string;
}

export default function ProjectAvailableUnits({
  className,
  availableUnits,
  title,
  description,
}: ProjectAvailableUnitsProps) {
  const { t } = useTranslation();
  const tableTranslator = "project.sections.available_units.table.header_name";

  const renderTypoCell = (content: string | number) => (
    <Typo
      tag={ETypoTag.SPAN}
      variant={ETypoVariant.BODY_TITLE_16}
      color={ETypoColor.TABLE_TEXT}
    >
      {content}
    </Typo>
  );

  const COLUMNS: Column[] = [
    {
      headerName: t(`${tableTranslator}.unit_type`),
      field: "title",
      renderCell: (row) => renderTypoCell(row?.title ?? ""),
    },
    {
      headerName: t(`${tableTranslator}.available`),
      field: "availableUnits",
      headerCellClassName: "text-center",
      cellClassName: "text-center",
      renderCell: (row) => renderTypoCell(row?.availableUnits ?? ""),
    },
    {
      headerName: t(`${tableTranslator}.size`),
      field: "size",
      headerCellClassName: "text-center",
      cellClassName: "text-center",
      renderCell: (row) =>
        renderTypoCell(formatRangeValue(row?.minArea ?? 0, row?.maxArea ?? 0)),
    },
    {
      headerName: t(`${tableTranslator}.psf`),
      field: "psf",
      headerCellClassName: "text-center",
      cellClassName: "text-center",
      renderCell: (row) =>
        renderTypoCell(formatCurrencyRange(row?.minPSF ?? 0, row?.maxPSF ?? 0)),
    },
    {
      headerCellClassName: "text-right",
      cellClassName: "text-right",
      headerName: t(`${tableTranslator}.price`),
      field: "price",
      renderCell: (row) =>
        renderTypoCell(
          formatCurrencyRange(row?.minPrice ?? 0, row?.maxPrice ?? 0)
        ),
    },
  ];

  return (
    <div
      className={cn(
        "flex flex-col gap-y-6 rounded-xl py-6 lg:gap-y-5 lg:rounded-lg lg:py-4.5",
        className
      )}
    >
      <div className="flex justify-between px-0 lg:px-6">
        <div className="space-y-2">
          <Typo
            className="font-bold"
            variant={ETypoVariant.HEADER_PROJECT_24}
            tag={ETypoTag.H3}
            color={ETypoColor.PROJECT_HEADING}
          >
            {title}
          </Typo>
          {description && (
            <Typo variant={ETypoVariant.SUB_TITLE_16} tag={ETypoTag.P}>
              {description}
            </Typo>
          )}
        </div>
      </div>
      <div className="overflow-x-hidden">
        <HorizontalTable
          classNames={{
            wrapper: "lg:px-6",
            header: " > th: px-3 py-2 text-base font-normal",
          }}
          columns={COLUMNS}
          rows={availableUnits}
          variant="project"
          noDataLabel={t("no_unit_available")}
        />
      </div>
    </div>
  );
}
