import { forwardRef } from "react";

export interface SectionProjectProps {
  id: string;
  className?: string;
  children: React.ReactNode;
}

export type Ref = HTMLDivElement;

const SectionProject = forwardRef<Ref, SectionProjectProps>(
  ({ id, className, children }, ref) => {
    return (
      <section ref={ref} id={id} className={className}>
        {children}
      </section>
    );
  }
);

SectionProject.displayName = "SectionProject";

export default SectionProject;
